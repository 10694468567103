import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SvgBlob } from '../../images/blobs/orange-blob.svg';

export const Blob: React.FC<{ color: string; style?: React.CSSProperties }> = ({
  color,
  style,
}) => {
  return (
    <BlobWrapper>
      <SvgBlob
        style={{
          fill: color,
          transition: 'fill 0.5s cubic-bezier(0.16, 0.97, 0.46, 0.95)',
          ...style,
        }}
        {...{ viewBox: '0 0 496 436' }}
      />
    </BlobWrapper>
  );
};

const animate = keyframes`
  0%   { transform: scale(1)  }
  20%  { transform: scale(0.7, 1) rotate(72); }
  40%  { transform: scale(1, 1.3)  rotate(190deg); }
  60%  { transform: scale(1.3)  rotate(144deg); }
  80%  { transform: scale(0.9, 0.8) rotate(288deg); }
  100% { transform: scale(1)  rotate(359deg); }
`;

const BlobWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  left: 25%;
  z-index: -1;
  animation: ${animate} 85s ease-in-out infinite;
  transform-origin: 50% 50%;
`;
