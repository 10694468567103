import { graphql } from 'gatsby';
import React from 'react';
import { isIE } from 'react-device-detect';
import styled from 'styled-components';
import { BlueBlob } from '../components/blobs/BlueBlob';
import { Blob } from '../components/blobs/OrangeBlob';
import BrowserNotSupported from '../components/BrowserNotSupported';
import { Box } from '../components/common/Box';
import { H1 } from '../components/common/Heading';
import { InnerGrid } from '../components/common/InnerGrid';
import { AnimatedLinkWrapper } from '../components/common/NavLink';
import { Text } from '../components/common/Text';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import arrow from '../images/icons/link-arrow.svg';
import { color } from '../theme/color';
import { themeTransition } from '../theme/themeTransition';
import { extractWithLocale } from '../utils/extractWithLocale';
import { getLinkTitle, getLocalizedLink } from '../utils/getLocalizedLink';
import { PageProps } from '../utils/PageProps';
import { NotFoundPageQuery } from '../__generated__/graphql';

const NotFoundPage: React.FC<PageProps<NotFoundPageQuery>> = ({ data, location }) => {
  const page = data!.sanityConfig!.page!;
  const locale = location.pathname.indexOf('/en/') === 0 ? 'en' : 'sv';

  if (isIE) {
    return <BrowserNotSupported locale={locale} />;
  }

  return (
    <Layout location={location} locale={locale}>
      <Seo title="404: Not found" location={location} />
      <Box gridColumn="1 / -1" position="relative" my={0} height="100%">
        <InnerGrid>
          <Box gridColumn="2 / -2" my={[5, 6]}>
            <H1 fontSize={[6, 9, 9, 150]} fontWeight={500} mb={3}>
              {extractWithLocale(page.heading, locale)}
            </H1>
            <Text>{extractWithLocale(page.text, locale)}</Text>
            {page.links && (
              <Box display="flex" flexDirection="column" mt={6}>
                {page.links?.heading && (
                  <Text>{extractWithLocale(page.links?.heading, locale)}</Text>
                )}
                {page.links?.links?.map((link, i) => {
                  return (
                    <AnimatedLinkWrapper position="relative" key={link?._key ?? i}>
                      <Text fontSize={[3, 3, 3, 5]} mb={[2, 0]}>
                        <LinkWithArrow
                          className="link-elem"
                          href={getLocalizedLink(link, locale)}
                          target={link?.__typename === 'SanityOutboundLink' ? '_blank' : undefined}
                        >
                          {getLinkTitle(link, locale)}
                        </LinkWithArrow>
                      </Text>
                    </AnimatedLinkWrapper>
                  );
                })}
              </Box>
            )}
          </Box>
        </InnerGrid>
        <Box position="absolute" zIndex={-1} width="100%" height="100%" overflow="hidden" top="0">
          <Blob color={color.orange} />
          <BlueBlob />
          {/* <GreenBlob /> */}
        </Box>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;

const LinkWithArrow = styled.a`
  &::before {
    content: url(${arrow});
    position: absolute;
    left: -2em;
    bottom: -0.1em;
    width: 1em;
    transition: ${themeTransition(['left'])};
  }

  &:hover {
    &::before {
      left: -1.5em;
    }
  }
`;

export const query = graphql`
  query NotFoundPageQuery {
    sanityConfig {
      page: notFoundPage {
        heading {
          en

          sv
        }
        text {
          en

          sv
        }
        links {
          heading {
            en
            sv
          }
          links {
            __typename
            ... on SanityOutboundLink {
              _key
              url
              linkText {
                en
                sv
              }
            }
            ... on SanityRelativeUrl {
              _key
              relativeUrl {
                sv
                en
              }
              title {
                sv
                en
              }
            }
            ... on SanityCase {
              _key
              name {
                en

                sv
              }
              slug {
                en {
                  current
                }
                sv {
                  current
                }
              }
            }

            ... on SanityPage {
              _key
              name {
                en

                sv
              }
              slug {
                en {
                  current
                }
                sv {
                  current
                }
              }
            }
            ... on SanityArticle {
              _key
              name {
                en

                sv
              }
              slug {
                en {
                  current
                }
                sv {
                  current
                }
              }
            }
          }
        }
      }
    }
  }
`;
