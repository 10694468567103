import React from 'react';
import { ReactComponent as Blob } from '../../images/blobs/blue-blob.svg';
import styled, { keyframes } from 'styled-components';

export const BlueBlob: React.FC = () => {
  return (
    <BlobWrapper>
      <Blob />
    </BlobWrapper>
  );
};

const animate = keyframes`
  0%   { transform: scale(1)   translate(20vw, 5vh); }
  20%  { transform: scale(1, 1.2) translate(20vw, 10vh) rotate(-160deg); }
  40%  { transform: scale(0.8, 1) translate(15vw, 14vh) rotate(-200deg); }
  60%  { transform: scale(1.3) translate(18vw, 16vh) rotate(-220deg); }
  80%  { transform: scale(0.6, 0.4) translate(12vw, 13vh) rotate(-320deg); }
  100% { transform: scale(1, 1)   translate(20vw, 5vh) rotate(-359deg);}
`;

const BlobWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  animation: ${animate} 75s ease-in-out infinite;
  transform-origin: 50% 50%;
`;
