import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Thing } from 'schema-dts';
import { background } from 'styled-system';
import { createOrganizationMicrodata } from '../utils/createMicrodata';
import { isNotNull } from '../utils/isNotNull';
import { useLocale } from './CurrentLocale';

export const Seo: React.FC<{
  title: string;
  description?: string;
  meta?: any[];
  microData?: Thing[];
  clearTemplate?: boolean | null;
  keywords?: string[];
  image?: { url: string; width: number; height: number } | null;
  type?: 'article' | null;
  location?: Location;
  backgroundColor?: string;
}> = ({
  description = '',
  meta = [],
  keywords = [],
  title,
  clearTemplate,
  image,
  type,
  microData = [],
  location,
  backgroundColor,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query SeoQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const org = createOrganizationMicrodata();

  const allMicroData = [org, ...microData];

  const lang = useLocale();

  const siteMeta = [
    description
      ? {
          name: `description`,
          content: description || '',
        }
      : null,
    {
      property: `og:title`,
      content: title || '',
    },
    description
      ? {
          property: `og:description`,
          content: description || '',
        }
      : null,
    {
      property: `og:type`,
      content: type ? type : `website`,
    },
    {
      property: `og:image:type`,
      content: `image/gif`,
    },
    {
      property: `og:site_name`,
      content: `Esatto`,
    },
    location
      ? {
          property: `og:url`,
          content: location.href,
        }
      : null,
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    ...(image
      ? [
          {
            property: `og:image`,
            content: image.url,
          },
          {
            property: `og:image:width`,
            content: image.width,
          },
          {
            property: `og:image:height`,
            content: image.height,
          },
        ]
      : [
          {
            property: `og:image`,
            content: require('../images/esatto.gif'),
          },
          {
            property: `og:image:width`,
            content: 1200,
          },
          {
            property: `og:image:height`,
            content: 630,
          },
        ]),
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title || '',
    },
    description
      ? {
          name: `twitter:description`,
          content: description || '',
        }
      : null,
  ].filter(isNotNull);

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={clearTemplate ? '%s' : `%s · ${site.siteMetadata.title}`}
      meta={siteMeta
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {allMicroData.map((data, index) => (
        <script key={`${(data as any)['@type']}${index}`} type="application/ld+json">
          {JSON.stringify(data, null, 2)}
        </script>
      ))}
      <link rel="preconnect" href="https://www.google-analytics.com/" crossOrigin="anonymous" />
      <link rel="preconnect" href="https://www.googletagmanager.com/" crossOrigin="anonymous" />
    </Helmet>
  );
};
