import { BreadcrumbList, Organization, WithContext } from 'schema-dts';
import logoImage from '../images/logo.png';

const url = process.env.GATSBY_WEBSITE_URL || '';

export const createOrganizationMicrodata = (): WithContext<Organization> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url,
    name: "Esatto AB",
    logo: `${url}${logoImage}`,
    sameAs: [
      "https://www.facebook.com/esattose/",
      "https://www.linkedin.com/company/esatto/",
      "https://www.instagram.com/esatto.se/"
    ]
  };
};

export const createBreadcrumbListMicrodata = (): WithContext<BreadcrumbList> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: '1',
        name: 'Test',
        item: `${url}/whaever`,
      },
    ],
  };
};
